.a-title {
  &-h1 {
    text-align: center;
    width: 100%;
    font-size: 10vw;
    padding: 10vw 0 6vw 0;
    font-weight: 700;
    margin: 0;
    border-bottom: $rose-pale solid 2px;
    @media (min-width: $b-mobile) {
      font-size: 5vw;
      padding: 8vw 0 6vw 0;
    }
    @media (min-width: $b-desktop) {
      font-size: 5vw;
      padding: 6vw 0 4vw 0;
    }
  }
  &-h3 {
    font-size: 6.5vw;
    font-weight: 500;
    @media (min-width: $b-desktop) {
      font-size: 3.5vw;
    }
  }
}
