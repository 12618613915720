.o-accueil {
  &-realisation {
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;

    @media (min-width: $b-mobile) {
      flex-wrap: wrap;
      flex-direction: row;
      justify-content: center;
    }
    &-zone {
      z-index: 2;
      width: 95%;
      display: flex;
      flex-direction: column;
      margin: 25px 10px 0;
      @media (min-width: $b-mobile) {
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: space-between;
      }
      @media (min-width: $b-desktop) {
        // width: 85%;
        margin: auto;
      }
      &-title {
        width: 100%;
        position: relative;
        &::before {
          content: none;
          @media (min-width: $b-contact) {
            content: "";
            position: absolute;
            top: 14px;
            left: 7px;
            width: 35%;
            height: 60%;
            background-color: $mango-tango-transparent-55;
            z-index: -1;
          }
        }
      }
    }
    &-figcaption {
      position: relative;
      top: -120px;
      text-align: center;
      margin: 0 20px;
      background-color: white;
      border-radius: 2%;
      opacity: 0;
      transition: ease-in-out 0.2s;
      @media (min-width: $b-mobile) {
        top: -160px;
        font-size: 1.2rem;
      }
      @media (min-width: $b-desktop) {
        top: -140px;
        font-size: 1.2rem;
      }
      @media (min-width: $b-large-desktop) {
        top: -220px;
        font-size: 1.2rem;
      }
    }
    &-figure {
      font-weight: bold;
      transition: ease-in-out 0.2s;
      text-decoration: none;
      color: $onyx;
      // box-shadow: 5px 9px 15.5px -1px rgba(0, 0, 0, 0.15);
      @media (min-width: $b-mobile) {
        width: 75%;
      }
      @media (min-width: $b-desktop) {
        width: 42%;
        margin: 35px 0;
      }
      &:hover {
        cursor: pointer;
        transform: scale(1.05);
        opacity: 0.9;
        color: $onyx;
        & .o-accueil-realisation-figcaption {
          opacity: 1;
        }
      }
      & .o-accueil-realisation-figcaption-span {
        width: 100%;
        display: block;
      }
      & img {
        width: 100%;
      }
    }
  }
}
