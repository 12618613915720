.o-accueil-project {
  position: relative;

  &-image {
    max-width: 100%;
    margin: auto;
  }
  &--title {
    font-size: 12vw;
    text-align: center;
    font-weight: 500;
    padding: 60px 0 30px;
    background-color: $onyx;
    margin: 0;
    color: $platinum;
    @media (min-width: $b-mobile) {
      font-size: 7vw;
      padding: 8vw 0 6vw 0;
    }
    @media (min-width: $b-desktop) {
      padding: 6vw 0 3vw 0;
    }
  }
  &--article {
    position: relative;
    margin-top: -10px;
    &-description {
      color: $platinum;
      line-height: 25px;
      margin-top: 20px;
      font-size: 16px;
      @media (min-width: $b-large-desktop) {
        margin-top: 30px;
        line-height: 35px;
        font-size: 20px;
        margin-bottom: 0;
      }
    }
    &-title {
      font-size: 8vw;
      text-align: center;
      font-weight: 500;
      padding: 20px;
      background-color: $onyx;
      margin: 0;
      color: $platinum;
      &-h2 {
        font-size: 5vw;
        font-weight: 500;
        background-color: $onyx;
        margin: 30px 0 0 0;
        color: $platinum;
      }
    }
    &-zone {
      background-color: $onyx;
    }
    &-image {
      max-width: 100%;
      margin: 0 auto;
      display: block;
      @media (min-width: $b-mobile) {
        width: 65%;
      }
      // @media (min-width: $b-desktop) {
      //   height: 2vw;
      // }
    }
    &-svg-bottom {
      margin: 0 0 -15px 0;
      width: 100%;
      transform: rotate(180deg);
      @media (min-width: $b-desktop) {
        margin: 0 0 -125px 0;
      }
      @media (min-width: $b-large-desktop) {
        margin: 0 0 -10px 0;
      }
      & img {
        width: 100%;
      }
    }
  }
  &--techno {
    width: 100%;

    &-title {
      text-align: center;
      margin: 35px 0;
      font-size: 1.8rem;
      font-weight: 600;
      @media (min-width: $b-desktop) {
        font-size: 2.8rem;
      }
    }
    &-svg-top {
      margin: -40px 0 0;
      width: 100%;
      @media (min-width: $b-mobile) {
        margin: -120px 0 0;
      }
      @media (min-width: $b-desktop) {
        margin: -190px 0 0;
      }
      @media (min-width: $b-large-desktop) {
        margin: -20px 0 0;
      }
      & img {
        width: 100%;
      }
    }
    &-list {
      list-style: none;
      display: grid;
      grid-template-columns: auto auto auto;
      padding: 0 20px;
      &-text {
        margin: 0;
        font-size: 20px;
        font-weight: 600;
      }
      & li {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        padding: 10px;
        margin: auto;
        & span {
          width: 100%;
          text-align: center;
          margin-bottom: 10px;
        }
      }
      & img {
        max-width: 48px;
        @media (min-width: $b-desktop) {
          max-width: 48px;
        }
      }
    }
  }
  &--liked {
    // background-color: $onyx;
    & .carousel {
      max-width: 100%;
      @media (min-width: $b-desktop) {
        max-width: 75%;
      }
      &-caption {
        background-color: rgba(252, 244, 248, 0.8);
      }
    }
    &-zone {
      background-color: $onyx;
      padding-bottom: 25px;
    }
  }
}
