.project_popup {
  &-section {
    position: fixed;
    width: 100%;
    gap: 10px;
    top: 0;
    height: 100vh;
    left: 0;
    flex-direction: column;
    display: flex;
    flex-wrap: nowrap;
    padding: 10px;
    overflow: auto;
    z-index: 999;
    background-color: $platinum-transparent-85;
  }
}

.bento {
  &-header {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    &-section {
      flex: 2;
      display: flex;
      flex-direction: row;
      gap: 10px;
      margin-bottom: 10px;
    }
    &-item {
      flex: 1;
      height: 70px;
      border-radius: 15px;
      background: rgba(248, 246, 247, 0.2);
      box-shadow: $shadow-bento;
    }
  }
  &-main {
    height: 100%;
    display: flex;
    flex-direction: row;
    gap: 10px;
    &-project {
      flex: 2;
      display: flex;
      flex-direction: column;
      gap: 10px;
      &-description {
        position: relative;
        flex: 3;
        border-radius: 15px;
        box-shadow: $shadow-bento;
        overflow: hidden;
        transition: ease 0.3s;
        mix-blend-mode: multiply;

        & p {
          margin: 10px;
          position: relative;
          z-index: 1;
          font-weight: 500;
        }
        & h2 {
          margin: 10px;
          position: relative;
          z-index: 1;
          font-weight: 700;
        }
        &:hover {
          & .bento-hover-rotate {
            background-size: 77%;
          }
        }
      }
      &-backimage {
        background-image: url(../../Images/triangle_forest.png);
        background-size: 75%;
        background-position: center;
        height: 120%;
        position: absolute;
        width: 150%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        transition: ease 0.3s;
      }
      &-button {
        flex: 0.7;
        display: flex;
        gap: 10px;
        &-item {
          flex: 1;
          border-radius: 15px;
          box-shadow: $shadow-bento;
          &:hover {
            background-color: $background-interior-input-transparent-75;
          }
        }
      }
      &-image {
        flex: 2;
        border-radius: 15px;
        overflow: hidden;
        box-shadow: $shadow-bento;

        & img {
          width: 100%;
          position: relative;
          height: auto;
          border-radius: 15px;
          object-fit: cover;
        }
      }
    }
    &-aside {
      display: flex;
      flex-direction: column;
      gap: 10px;
      flex: 0.8;
      &-items {
        display: flex;
        flex: 1;
        background-color: cadetblue;
        border-radius: 15px;
        overflow: hidden; // Ensure images don't overflow their container

        & img {
          width: 120%;
          position: relative;
          border-radius: 15px;
          object-fit: cover;
          aspect-ratio: 16 / 9;
        }
        &:first-child {
          flex: 0.7;
          & img {
          }
        }
      }
    }
    &-bottom {
      display: flex;
      background: rgba(237, 125, 58, 0.2);
      height: 20%;
      margin-top: 10px;
      border-radius: 15px;
      box-shadow: $shadow-bento;
    }
  }
}

.url-image-01 {
}

.bento {
  &-section {
    display: flex;
    gap: 10px;
    max-height: 100%;
    // margin-bottom: 10px;
  }
  &-item {
    display: flex;
    flex-direction: column;
    box-shadow: $shadow-bento;
    overflow: hidden;
    transition: ease 0.2s;
    position: relative;
    mix-blend-mode: multiply;
    & .back-button {
      width: 100%;
      height: 100%;
      font-size: 2.5vw;
      font-weight: 700;
      overflow: hidden;
      background: none;
      border-color: transparent;
      transition: ease-out 0.15s;
      &:hover {
        background-color: $rose-pale-transparent;
      }
      &:active {
        background-color: $rose-pale-transparent-35;
      }
    }

    &.button {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      & p {
        font-size: 2.5vw;
        font-weight: 600;
        margin: 10px;
        @media screen and (min-width: $b-large-1200) {
          font-size: 1.8vw;
          font-weight: 600;
        }
      }
      &:hover {
        background-color: $platinum-transparent-85;
      }
      &.active {
        background-color: $rose-pale-transparent-35;
        & img {
          filter: blur(1.5px) contrast(85%);
          transition: ease-in-out 0.3s;
        }
      }
    }

    & .text {
      padding: 5px;
      margin: 5px;
      border-radius: 15px;
      position: relative;
      z-index: 1;
      font-weight: 500;
      font-size: 0.9rem;
      background-color: $background-interior-input-transparent-75;
      @media screen and (min-width: $b-large-1200) {
        font-size: 1.6rem;
        font-weight: 500;
      }
    }
    & .textHolder {
      text-align: center;
      margin-top: 50px;
      font-size: 1.3rem;
      font-weight: 700;
    }
    &.textDescriptionShow {
      & .mainImgText {
        max-width: 100%;
        height: 100%;
        overflow: hidden;
        opacity: 1;
      }
    }
    & .mainImgText {
      font-size: 1.3rem;
      font-weight: 600;
      max-width: 100%;
      height: 0;
      overflow: hidden;
      opacity: 0;
      transition: ease 0.3s;
    }
    & h2 {
      margin: 10px;
      position: relative;
      z-index: 1;
      text-align: center;
      font-weight: 700;
      background-color: $background-interior-input-transparent-75;
    }

    &.hover2 {
      &:hover {
        transform: scale(1.03);
        cursor: pointer;
      }
    }
    &.hover3 {
      &:hover {
        cursor: pointer;
        // filter: blur(1.5px) contrast(85%);
      }
    }
    &.hover1 {
      &:hover {
        transform: scale(1.01);
      }
    }
    & .image {
      width: 120%;
      height: 120%;
      position: relative;
      border-radius: 15px;
      object-fit: cover;
      aspect-ratio: 16 / 9;
    }
    & .mainImg {
      position: absolute;
      width: 100%;
      left: 0;
      height: 105%;
      object-fit: cover;
    }
    &-bg {
      background-size: 75%;
      background-position: center;
      height: 120%;
      position: absolute;
      width: 100%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      transition: ease 0.3s;
      // mix-blend-mode: multiply;
      animation: bgMoveZoom 30s infinite;
    }
  }
}

@keyframes bgMoveZoom {
  0% {
    transform: scale(1) translate(-50%, -50%);
  }
  33% {
    transform: scale(1.03) translate(-47%, -47%);
  }
  66% {
    transform: scale(1.05) translate(-50%, -50%);
  }
  100% {
    transform: scale(1) translate(-50%, -50%);
  }
}
