.o-contact {
  width: 100%;
  padding: 30px 10px;
  @media (min-width: $b-contact) {
    padding: 30px 10px;
  }
  &-content {
    width: 100%;
    margin: 0 0 55px;
    @media (min-width: $b-contact) {
      width: 450px;
      margin: 0 15px 55px;
    }
  }
  &-title {
    &.a-title-h2 {
      width: 95%;
      font-weight: 600;
      text-align: center;
      border: none;
      z-index: 1;
      position: relative;
      margin-bottom: 45px;
      text-decoration: underline 8px $rose-pale-transparent;
      margin: 0 auto 45px;
      background-color: $background-interior-input-transparent-75;
      @media (min-width: $b-contact) {
        padding-bottom: 0;
        text-align: left;
        text-decoration: none;
      }
      &::before {
        content: none;
        @media (min-width: $b-contact) {
          content: "";
          position: absolute;
          top: 14px;
          left: 7px;
          width: 35%;
          height: 60%;
          background-color: rgba(239, 71, 111, 0.75);
          z-index: -1;
        }
      }
    }
  }
  &-zone {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }
  &-form {
    width: 100%;
    background-color: $spanish-viridian-hover-transparent-75;
    position: relative;
    padding: 15px 0 25px;
    color: $onyx;
    margin: auto;
    box-shadow: 0px 2px 16px 2px rgba(0, 0, 0, 0.25);
    @media (min-width: $b-contact) {
      width: 450px;
    }
    &-title {
      margin-bottom: 25px;
      font-size: 1.3em;
      text-align: center;
      font-weight: 600;
      position: relative;
      z-index: 1;
      background-color: $background-interior-input-transparent-75;
    }
    &-input {
      margin: 10px 0;
      & label {
        font-weight: 600;
        width: 100%;
        display: flex;
        & .o-contact-form-required {
          margin-left: 10px;
          font-size: 18px;
        }
      }
      & textarea {
        background-color: $background-interior-input;
        outline: none;
        width: 100%;
        border: none;
        height: 185px;
      }
      & input {
        outline: none;
        border: none;
        width: 100%;
        background-color: $background-interior-input;
      }
    }
    &-submit {
      display: block;
      padding: 5px 55px;
      font-size: 18px;
      font-weight: 600;
      background-color: $background-input;
      color: black;
      border: none;
      margin: 15px auto 0;
      transition: ease-out 0.25s;
      &:hover {
        background-color: $onyx;
        color: $platinum;
      }
      &:active {
        background-color: $mango-tango;
        color: black;
      }
    }
    &-required {
      font-size: 14px;
      font-weight: 700;
      display: block;
    }
  }
  &-fieldset {
    padding: 0 20px;
  }
  &-medialink {
    &-title {
      font-size: 1.3em;
      font-weight: 600;
      text-align: center;
      margin: 0 45px 25px;
      background-color: $background-interior-input-transparent-75;
    }
    &-list {
      padding: 0;
      list-style: none;
    }
    &-item {
      &-icons {
        width: 35px;
        height: 35px;
        display: block;
        z-index: 1;
        margin: auto 15px;
        & img {
          width: 100%;
        }
      }
      position: relative;
      display: flex;
      transition: ease-in-out 0.3s;
      background-color: $platinum-transparent-75;
      box-shadow: 5px 9px 15.5px -1px rgba(0, 0, 0, 0.15);
      border-radius: 10px;
      padding: 0 10px;
      margin: 20px 0;
      &:hover {
        transition: ease-in-out 0.3s;
        transform: scale(1.08);
        background-color: $rose-pale-transparent;
      }
      &:active {
        background-color: $mango-tango-transparent-55;
      }
      & a {
        justify-content: center;
        padding: 0;
        width: 100%;
        font-weight: 500;
        position: relative;
        z-index: 1;
        text-decoration: none;
        color: black;
        display: flex;
        transition: ease-in-out 0.3s;
        padding: 10px 15px;
        margin: auto;
        font-weight: 700;
        & p {
          margin: auto 0;
        }
      }
    }
  }
}
