@import "./norms";
@import "./Organism/header";
@import "./Organism/accueil-news";
@import "./Organism/accueil-projet";
@import "./Organism/footer";
@import "./Organism/background";
@import "./Organism/organismProfil";
@import "./Organism/organismContact";
@import "./Organism/project_popup";
@import "./Organism/organismProject";
@import "./Organism/navigation-v2";
@import "./Pages/accueil";
@import "./Atoms/link";
@import "./Atoms/title";
@import "./Atoms/form";
@import "bootstrap/dist/css/bootstrap.min.css";

html {
  background-color: $platinum;
  color: black;
}
