.p-accueil {
  height: 100%;
  width: 100%;
  &-background {
    position: fixed;
    z-index: 1;
    width: 100%;
    height: 100%;
    @media (min-width: $b-desktop) {
      top: 0;
    }
  }
}
