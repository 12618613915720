.o-accueil {
  &-presentation {
    width: 95%;
    background-color: $platinum-transparent-75;
    box-shadow: 5px 9px 15.5px -1px rgba(0, 0, 0, 0.15);
    padding: 30px 10px;
    border-radius: 10px;
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto 50px;
    z-index: 1;
    @media (min-width: $b-mobile) {
      padding: 30px 40px;
    }
    &-title {
      width: 100%;
      font-weight: 600;
      text-align: center;
      margin-bottom: 15px;
      position: relative;
      z-index: 1;
      &::before {
        @media (min-width: $b-mobile) {
          content: "";
          position: absolute;
          top: 21px;
          left: 9px;
          width: 45%;
          height: 35%;
          background-color: rgba(44, 181, 124, 0.75);
          z-index: -1;
        }
      }
      @media (min-width: $b-mobile) {
        text-align: left;
      }
    }
    &-description {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      flex-direction: column;
      @media (min-width: $b-mobile) {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
      }
      @media (min-width: $b-desktop) {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        flex-direction: row;
      }
    }
    &-text {
      width: 90%;
      display: block;
      margin: auto auto;
      @media (min-width: $b-mobile) {
        width: 85%;
        margin: 15px auto 0;
      }
      @media (min-width: $b-desktop) {
        width: 65%;
      }
      & p {
        // background-color: $mango-tango-transparent-55;
      }
      &-subtitle {
        margin-top: 15px;
        font-weight: 600;
        font-size: 1em;
        & span {
          font-weight: 900;
          transition: ease-in-out 0.15s;
          text-decoration: underline 3px $spanish-viridian-hover;
          &:hover {
            transition: ease-in-out 0.15s;
            text-decoration: underline 3px $mango-tango;
            cursor: pointer;
          }
        }
      }
    }
    &-image {
      width: 55%;
      display: block;
      margin: auto auto;
      padding-top: 20px;
      @media (min-width: $b-mobile) {
        width: 45%;
        display: block;
      }
      @media (min-width: $b-desktop) {
        width: 25%;
        margin: auto 0;
        padding-top: 0;
      }
    }
    &-image-profil {
      width: 100%;
      border-radius: 50%;
      box-shadow: 5px 9px 15.5px -1px rgba(0, 0, 0, 0.15);
      transition: 0.25s ease-in;

      &:hover {
        transform: scale(1.05);
      }
    }
    &-button {
      margin: 25px auto 0;
      border-radius: 15px;
      text-decoration: none;
      font-weight: 600;
      display: flex;
      position: relative;
      transition: ease-in-out 0.2s;
      &::before {
        content: "";
        position: absolute;
        top: 8px;
        left: 12px;
        width: 100%;
        height: 65%;
        background-color: $spanish-viridian;
        z-index: 0;
        transition: ease-in-out 0.3s;
      }
      &:hover::before {
        background-color: $spanish-viridian-hover;
        transition: ease-in-out 0.3s;
        transform: scale(1.08);
        height: 75%;
        top: 5px;
        left: 0;
      }
      &:active::before {
        background-color: $onyx;
        transition: ease-in-out 0.3s;
        transform: scale(1.08);
        height: 75%;
        top: 5px;
        left: 0;
      }
      @media (min-width: $b-mobile) {
        // width: 100%;
      }
      & a {
        z-index: 1;
        text-decoration: none;
        color: white;
        transition: ease-in-out 0.3s;
        padding: 10px 15px;
        margin: auto;
        &:hover {
          transition: ease-in-out 0.3s;
          color: $platinum;
          transform: scale(1.08);
        }
      }
    }
    &-technos {
      width: 100%;
      &-list {
        display: flex;
        flex-wrap: wrap;
        padding: 0;
        @media (min-width: $b-mobile) {
        }
      }
      &-item {
        height: fit-content;
        padding: 2px 5px;
        border-radius: 20px;
        background-color: $background-input;
        margin: 5px 10px;
        display: flex;
        align-items: center;
        box-shadow: 4px 7px 11.9px -6px rgba(0, 0, 0, 0.15);
        transition: ease-in-out 0.2s;
        user-select: none;
        @media (min-width: $b-mobile) {
          padding: 5px 10px;
        }

        &:hover {
          transform: scale(1.08);
          transition: ease-in-out 0.3s;
          cursor: pointer;
        }

        & p {
          margin: auto 5px;
          font-weight: 600;
          transition: ease-in-out 0.3s;
          font-size: 12px;
          @media (min-width: $b-mobile) {
            font-size: 16px;
          }
        }
      }
      &-icon img {
        display: block;
        width: 25px;
        height: 25px;
      }
    }
  }
}
