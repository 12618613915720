.o-header {
  &--title {
    margin-top: 90px;
    font-size: 4vw;
    text-align: center;
    font-weight: 700;
    z-index: 3;
    width: 100%;
    @media (max-width: $b-desktop) {
      font-size: 7vw;
      margin-top: 8vw;
      margin-bottom: 4vw;
    }
  }
  &--image {
    width: 100%;
  }
  &--menu {
    @media (min-width: $b-mobile) {
      display: flex;
      flex-wrap: wrap;
      width: 55%;
      margin-left: auto;
      justify-content: space-between;
      list-style: none;
      height: 50px;
      margin-bottom: 11vw;
    }
    &-item {
      width: 25%;
      text-align: center;
    }
  }
  &--content {
    z-index: 4;
    position: relative;
  }
}
